import React from "react";
import * as Icon from "react-feather";
import {PERMISSION_SUBJECTS} from "../../components/nominex/Permissions/permissionsUtils";

const navigationConfig = [
    {
        type: "groupHeader",
        groupTitle: "Clientes",
        subjects: [PERMISSION_SUBJECTS.client.name, PERMISSION_SUBJECTS.employee.name, PERMISSION_SUBJECTS.incidents.name, PERMISSION_SUBJECTS.vacations.name]
    },
    {
        id: "clientes",
        title: "Clientes",
        type: "item",
        icon: <Icon.Users size={20}/>,
        navLink: "/clients",
        subjects: [PERMISSION_SUBJECTS.client.name],
    },
    {
        id: "empleados",
        title: "Empleados",
        type: "item",
        icon: <Icon.UserCheck size={20}/>,
        navLink: "/employees",
        subjects: [PERMISSION_SUBJECTS.employee.name],
    },
    {
        id: "incidencias",
        title: "Incidencias",
        type: "item",
        icon: <Icon.Shield size={20}/>,
        navLink: "/incidents",
        subjects: [PERMISSION_SUBJECTS.incidents.name],
    },
    {
        id: "vacaciones",
        title: "Solicitudes Vacaciones",
        type: "item",
        icon: <Icon.LifeBuoy size={20}/>,
        navLink: "/vacations",
        subjects: [PERMISSION_SUBJECTS.vacations.name],
    },
    {
        type: "groupHeader",
        groupTitle: "Nóminas",
        subjects: [PERMISSION_SUBJECTS.paymentStrategies.name, PERMISSION_SUBJECTS.payroll.name, PERMISSION_SUBJECTS.bankLayouts.name, PERMISSION_SUBJECTS.payrollReceipts.name, PERMISSION_SUBJECTS.company.name, PERMISSION_SUBJECTS.payrollConcept.name]
    },
    {
        id: "estrategias_pago",
        title: "Estrategias Pago",
        type: "item",
        icon: <Icon.Settings size={20}/>,
        navLink: "/paymentStrategies",
        subjects: [PERMISSION_SUBJECTS.paymentStrategies.name],
    },
    {
        id: "nomina",
        title: "Nóminas",
        type: "item",
        icon: <Icon.DollarSign size={20}/>,
        navLink: "/payrolls",
        subjects: [PERMISSION_SUBJECTS.payroll.name],
    },
    {
        id: "layouts_bancarios",
        title: "Layouts Bancarios",
        type: "item",
        icon: <Icon.FileText size={20}/>,
        navLink: "/bankLayouts",
        subjects: [PERMISSION_SUBJECTS.bankLayouts.name],
    },
    {
        id: "recibos_nomina",
        title: "Recibos Nómina",
        type: "item",
        icon: <Icon.Inbox size={20}/>,
        navLink: "/payrollReceipts",
        subjects: [PERMISSION_SUBJECTS.payrollReceipts.name],
    },
    {
        id: "setting",
        title: "Configuración",
        icon: <Icon.Sliders size={20}/>,
        type: "collapse",
        subjects: [PERMISSION_SUBJECTS.company.name, PERMISSION_SUBJECTS.payrollConcept.name],
        children: [
            {
                id: "empresas",
                title: "Empresas Pagadoras",
                type: "item",
                icon: <Icon.Briefcase size={16}/>,
                navLink: "/companies",
                subjects: [PERMISSION_SUBJECTS.company.name],
            },
            {
                id: "conceptos",
                title: "Conceptos",
                icon: <Icon.Archive size={16}/>,
                type: "item",
                navLink: "/concepts",
                subjects: [PERMISSION_SUBJECTS.payrollConcept.name],
            }
        ]
    },
    {
        type: "groupHeader",
        groupTitle: "Administración",
        subjects: [PERMISSION_SUBJECTS.user.name, PERMISSION_SUBJECTS.expedients.name, PERMISSION_SUBJECTS.imports.name, PERMISSION_SUBJECTS.products.name, PERMISSION_SUBJECTS.intervalsPeriodicities.name]
    },
    {
        id: "usuarios",
        title: "Usuarios",
        type: "item",
        icon: <Icon.User size={20}/>,
        navLink: "/users",
        subjects: [PERMISSION_SUBJECTS.user.name],
    },
    {
        id: "expedients",
        title: "Expedientes Digitales",
        type: "item",
        icon: <Icon.File size={20}/>,
        navLink: "/expedients",
        subjects: [PERMISSION_SUBJECTS.expedients.name],
    },
    {
        id: "imports",
        title: "Importaciones",
        icon: <Icon.Upload size={20}/>,
        type: "item",
        navLink: "/imports",
        subjects: [PERMISSION_SUBJECTS.imports.name],
    },
    {
        id: "products",
        title: "Productos",
        icon: <Icon.Bookmark size={20}/>,
        type: "item",
        navLink: "/products",
        subjects: [PERMISSION_SUBJECTS.products.name],
    },
    {
        id: "periodicities",
        title: "Intervalos Periodicidades",
        icon: <Icon.Calendar size={20}/>,
        type: "item",
        navLink: "/intervalsPeriodicities",
        subjects: [PERMISSION_SUBJECTS.intervalsPeriodicities.name],
    },
    {
        type: "groupHeader",
        groupTitle: "Configuración Sistema",
        subjects: [
            PERMISSION_SUBJECTS.payrollConcept.name,
            PERMISSION_SUBJECTS.workActivity.name,
            PERMISSION_SUBJECTS.riskClass.name,
            PERMISSION_SUBJECTS.laborJourney.name,
            PERMISSION_SUBJECTS.paymentStrategyItem.name,
            PERMISSION_SUBJECTS.job.name,
            PERMISSION_SUBJECTS.billingService.name,
            PERMISSION_SUBJECTS.peopleType.name,
            PERMISSION_SUBJECTS.department.name,
            PERMISSION_SUBJECTS.clinic.name,
            PERMISSION_SUBJECTS.country.name,
            PERMISSION_SUBJECTS.state.name,
            PERMISSION_SUBJECTS.municipality.name,
            PERMISSION_SUBJECTS.zipCode.name,
            PERMISSION_SUBJECTS.colonie.name,
            PERMISSION_SUBJECTS.maritalStatus.name,
            PERMISSION_SUBJECTS.label.name,
            PERMISSION_SUBJECTS.periodicity.name,
            PERMISSION_SUBJECTS.workingTime.name,
            PERMISSION_SUBJECTS.attributeType.name,
            PERMISSION_SUBJECTS.bank.name,
            PERMISSION_SUBJECTS.companyType.name,
            PERMISSION_SUBJECTS.conceptType.name,
            PERMISSION_SUBJECTS.dataInputFormat.name,
            PERMISSION_SUBJECTS.dataInputType.name,
            PERMISSION_SUBJECTS.employerRecordType.name,
            PERMISSION_SUBJECTS.familyType.name,
            PERMISSION_SUBJECTS.foreignExchange.name,
            PERMISSION_SUBJECTS.paymentType.name,
            PERMISSION_SUBJECTS.productType.name,
            PERMISSION_SUBJECTS.salaryType.name,
            PERMISSION_SUBJECTS.taxRegime.name,
            PERMISSION_SUBJECTS.workerType.name
        ]
    },
    {
        id: "catalogs",
        title: "Catálogos",
        icon: <Icon.Database size={20}/>,
        type: "collapse",
        subjects: [
            PERMISSION_SUBJECTS.workActivity.name,
            PERMISSION_SUBJECTS.riskClass.name,
            PERMISSION_SUBJECTS.laborJourney.name,
            PERMISSION_SUBJECTS.paymentStrategyItem.name,
            PERMISSION_SUBJECTS.job.name,
            PERMISSION_SUBJECTS.billingService.name,
            PERMISSION_SUBJECTS.peopleType.name,
            PERMISSION_SUBJECTS.department.name,
            PERMISSION_SUBJECTS.clinic.name,
            PERMISSION_SUBJECTS.country.name,
            PERMISSION_SUBJECTS.state.name,
            PERMISSION_SUBJECTS.municipality.name,
            PERMISSION_SUBJECTS.zipCode.name,
            PERMISSION_SUBJECTS.colonie.name,
            PERMISSION_SUBJECTS.maritalStatus.name,
            PERMISSION_SUBJECTS.label.name,
            PERMISSION_SUBJECTS.periodicity.name,
            PERMISSION_SUBJECTS.workingTime.name,
            PERMISSION_SUBJECTS.attributeType.name,
            PERMISSION_SUBJECTS.bank.name,
            PERMISSION_SUBJECTS.companyType.name,
            PERMISSION_SUBJECTS.conceptType.name,
            PERMISSION_SUBJECTS.dataInputFormat.name,
            PERMISSION_SUBJECTS.dataInputType.name,
            PERMISSION_SUBJECTS.employerRecordType.name,
            PERMISSION_SUBJECTS.familyType.name,
            PERMISSION_SUBJECTS.foreignExchange.name,
            PERMISSION_SUBJECTS.paymentType.name,
            PERMISSION_SUBJECTS.productType.name,
            PERMISSION_SUBJECTS.salaryType.name,
            PERMISSION_SUBJECTS.taxRegime.name,
            PERMISSION_SUBJECTS.workerType.name
        ],
        children: [
            {
                id: "read_workActivity",
                title: "Actividades laborales",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/workActivities",
                subjects: [PERMISSION_SUBJECTS.workActivity.name],
            },
            {
                id: "read_riskClass",
                title: "Clases de riesgo",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/riskClasses",
                subjects: [PERMISSION_SUBJECTS.riskClass.name],
            },
            {
                id: "read_laborJourney",
                title: "Jornadas laborales",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/laborJourneys",
                subjects: [PERMISSION_SUBJECTS.laborJourney.name],
            },
            {
                id: "read_paymentStrategyItems",
                title: "Rubros Estrategias Pago",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/paymentStrategyItems",
                subjects: [PERMISSION_SUBJECTS.paymentStrategyItem.name],
            },
            {
                id: "read_job",
                title: "Puestos",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/jobs",
                subjects: [PERMISSION_SUBJECTS.job.name],
            },
            {
                id: "read_billingService",
                title: "Servicios facturación",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/billingServices",
                subjects: [PERMISSION_SUBJECTS.billingService.name],
            },
            {
                id: "read_peopleType",
                title: "Tipos personas",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/peopleTypes",
                subjects: [PERMISSION_SUBJECTS.peopleType.name],
            },
            {
                id: "read_department",
                title: "Departamentos",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/departments",
                subjects: [PERMISSION_SUBJECTS.department.name],
            },
            {
                id: "read_clinic",
                title: "Clínicas",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/clinics",
                subjects: [PERMISSION_SUBJECTS.clinic.name],
            },
            {
                id: "read_country",
                title: "Países",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/countries",
                subjects: [PERMISSION_SUBJECTS.country.name],
            },
            {
                id: "read_state",
                title: "Estados",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/states",
                subjects: [PERMISSION_SUBJECTS.state.name],
            },
            {
                id: "read_municipality",
                title: "Municipios",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/municipalities",
                subjects: [PERMISSION_SUBJECTS.municipality.name],
            },
            {
                id: "read_zipCode",
                title: "Códigos postales",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/zipCodes",
                subjects: [PERMISSION_SUBJECTS.zipCode.name],
            },
            {
                id: "read_colonie",
                title: "Colonias",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/colonies",
                subjects: [PERMISSION_SUBJECTS.colonie.name],
            },
            {
                id: "read_maritalStatus",
                title: "Estados civiles",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/maritalStatuses",
                subjects: [PERMISSION_SUBJECTS.maritalStatus.name],
            },
            {
                id: "read_label",
                title: "Etiquetas",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/labels",
                subjects: [PERMISSION_SUBJECTS.label.name],
            },
            {
                id: "read_periodicity",
                title: "Periodicidades",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/periodicities",
                subjects: [PERMISSION_SUBJECTS.periodicity.name],
            },
            {
                id: "read_workingTime",
                title: "Tiempos Laborales",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/workingTimes",
                subjects: [PERMISSION_SUBJECTS.workingTime.name],
            },
            {
                id: "read_attributeType",
                title: "Tipos atributos",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/attributesTypes",
                subjects: [PERMISSION_SUBJECTS.attributeType.name],
            },
            {
                id: "read_bank",
                title: "Bancos",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/banks",
                subjects: [PERMISSION_SUBJECTS.bank.name],
            },
            {
                id: "read_companyType",
                title: "Tipos sociedades",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/companiesTypes",
                subjects: [PERMISSION_SUBJECTS.companyType.name],
            },
            {
                id: "read_conceptType",
                title: "Tipos conceptos",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/conceptsTypes",
                subjects: [PERMISSION_SUBJECTS.conceptType.name],
            },
            {
                id: "read_dataInputFormat",
                title: "Formatos datos entrada",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/dataInputFormats",
                subjects: [PERMISSION_SUBJECTS.dataInputFormat.name],
            },
            {
                id: "read_dataInputType",
                title: "Tipos datos entrada",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/dataInputTypes",
                subjects: [PERMISSION_SUBJECTS.dataInputType.name],
            },
            {
                id: "read_employerRecordType",
                title: "Tipos registros patronales",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/employerRecordsTypes",
                subjects: [PERMISSION_SUBJECTS.employerRecordType.name],
            },
            {
                id: "read_familyType",
                title: "Tipos familiares",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/familyTypes",
                subjects: [PERMISSION_SUBJECTS.familyType.name],
            },
            {
                id: "read_foreignExchange",
                title: "Divisas",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/foreignExchanges",
                subjects: [PERMISSION_SUBJECTS.foreignExchange.name],
            },
            {
                id: "read_paymentType",
                title: "Tipos pagos",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/paymentTypes",
                subjects: [PERMISSION_SUBJECTS.paymentType.name],
            },
            {
                id: "read_productType",
                title: "Tipos productos",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/productsTypes",
                subjects: [PERMISSION_SUBJECTS.productType.name],
            },
            {
                id: "read_salaryType",
                title: "Tipos salarios",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/salaryTypes",
                subjects: [PERMISSION_SUBJECTS.salaryType.name],
            },
            {
                id: "read_workerType",
                title: "Tipos empleados",
                icon: <Icon.Bookmark size={16}/>,
                type: "item",
                navLink: "/catalogs/workersTypes",
                subjects: [PERMISSION_SUBJECTS.workerType.name],
            },
        ],
    }
];

export default navigationConfig;
