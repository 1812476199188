export const PERMISSION_ACTIONS = {
    read:{
        name:"read"
    },
    create:{
        name:"create"
    },
    update:{
        name:"update"
    },
    delete:{
        name:"delete"
    },
    export:{
        name:"export"
    },
    import:{
        name:"import"
    },
    payrollSendToPendingAuthorize:{
        name:"payrollPendingAuthorize"
    },
    payrollSendToAuthorized:{
        name:"payrollAuthorized"
    },
    payrollSendToUnauthorized:{
        name:"payrollUnauthorized"
    },
    bankLayoutSendToCreated:{
        name:"bankLayoutSendToCreated"
    },
    bankLayoutSendToCompleted:{
        name:"bankLayoutSendToCompleted"
    },
    bankLayoutSendToGenerated:{
        name:"bankLayoutSendToGenerated"
    },
};

export const PERMISSION_SUBJECTS = {
    home:{
        name:"home"
    },
    user:{
        name:"user",
        pluralLabel:"Usuarios"
    },
    employee:{
        name:"employee",
        pluralLabel:"Empleados",
        config:{
            import:{
                entities:[
                    {
                        label:"Información General",
                        enum:"EMPLEADOS"
                    },
                    {
                        label:"Cuentas bancarias",
                        enum:"EMPLEADOS_CUENTAS_BANCARIAS"
                    },
                    {
                        label:"Familiares",
                        enum:"EMPLEADOS_FAMILIARES"
                    },
                    {
                        label:"Asociar Empresas Pagadoras",
                        enum:"EMPLEADOS_EMPRESAS_PAGADORAS"
                    }
                ]
            }
        }
    },
    client:{
        name:"client",
        pluralLabel:"Clientes",
        config:{
            import:{
                entities:[
                    {
                        label:"Información General",
                        enum:"CLIENTES"
                    },
                    {
                        label:"Asociar Empresas Pagadoras",
                        enum:"CLIENTES_EMPRESAS_PAGADORAS"
                    }
                ]
            }
        }
    },
    company:{
        name:"company",
        pluralLabel:"Empresas",
        config:{
            import:{
                entities:[
                    {
                        label:"Información General",
                        enum:"EMPRESAS"
                    },
                    {
                        label:"Cuentas bancarias",
                        enum:"EMPRESAS_CUENTAS_BANCARIAS"
                    },
                    {
                        label:"Registros patronales",
                        enum:"EMPRESAS_REGISTROS_PATRONALES"
                    },
                    {
                        label:"Sucursales",
                        enum:"EMPRESAS_SUCURSALES"
                    },
                ]
            }
        }
    },
    payroll:{
        name:"payroll",
        pluralLabel:"Nóminas"
    },
    payrollConcept:{
        name:"payrollConcept",
        pluralLabel:"Conceptos"
    },
    vacations:{
        name:"vacations",
        pluralLabel:"Vacaciones"
    },
    incidents:{
        name:"incidents",
        pluralLabel:"Incidencias",
        config:{
            import:{
                entities:[
                    {
                        label:"Incidencias",
                        enum:"INCIDENCIAS"
                    },
                ]
            }
        }
    },
    imports:{
        name:"imports",
        pluralLabel:"Importaciones",
    },
    workActivity:{
        name:"workActivity",
        pluralLabel:"Actividades Laborales"
    },
    riskClass:{
        name:"riskClass",
        pluralLabel:"Clases Riesgo"
    },
    laborJourney:{
        name:"laborJourney",
        pluralLabel:"Jornadas Laborales"
    },
    paymentStrategyItem:{
        name:"paymentStrategyItem",
        pluralLabel:"Rubros Estrategias de Pago"
    },
    job:{
        name:"job",
        pluralLabel:"Puestos Laborales"
    },
    billingService:{
        name:"billingService",
        pluralLabel:"Servicios Facturación"
    },
    peopleType:{
        name:"peopleType",
        pluralLabel:"Tipos Personas"
    },
    department:{
        name:"department",
        pluralLabel:"Departamentos"
    },
    clinic:{
        name:"clinic",
        pluralLabel:"Clínicas"
    },
    country:{
        name:"country",
        pluralLabel:"Países"
    },
    state:{
        name:"state",
        pluralLabel:"Estados"
    },
    municipality:{
        name:"municipality",
        pluralLabel:"Municipios"
    },
    zipCode:{
        name:"zipCode",
        pluralLabel:"Códigos Postales"
    },
    colonie:{
        name:"colonie",
        pluralLabel:"Colonias"
    },
    maritalStatus:{
        name:"maritalStatus",
        pluralLabel:"Estados Civiles"
    },
    label:{
        name:"label",
        pluralLabel:"Etiquetas"
    },
    periodicity:{
        name:"periodicity",
        pluralLabel:"Periodicidades"
    },
    workingTime:{
        name:"workingTime",
        pluralLabel:"Tiempos Laborales"
    },
    attributeType:{
        name:"attributeType",
        pluralLabel:"Tipos Atributos"
    },
    bank:{
        name:"bank",
        pluralLabel:"Bancos"
    },
    companyType:{
        name:"companyType",
        pluralLabel:"Tipos Sociedades"
    },
    conceptType:{
        name:"conceptType",
        pluralLabel:"Tipos Conceptos"
    },
    dataInputFormat:{
        name:"dataInputFormat",
        pluralLabel:"Formatos Datos Entrada"
    },
    dataInputType:{
        name:"dataInputType",
        pluralLabel:"Tipos Datos Entrada"
    },
    employerRecordType:{
        name:"employerRecordType",
        pluralLabel:"Tipos Registros Patronales"
    },
    familyType:{
        name:"familyType",
        pluralLabel:"Tipos Familiares"
    },
    foreignExchange:{
        name:"foreignExchange",
        pluralLabel:"Divisas"
    },
    paymentType:{
        name:"paymentType",
        pluralLabel:"Tipos Pagos"
    },
    productType:{
        name:"productType",
        pluralLabel:"Tipos Productos"
    },
    salaryType:{
        name:"salaryType",
        pluralLabel:"Tipos Salarios"
    },
    taxRegime:{
        name:"taxRegime",
        pluralLabel:"Regímenes Fiscales"
    },
    workerType:{
        name:"workerType",
        pluralLabel:"Tipos Empleados"
    },
    payrollSetup:{
        name:"payrollSetup",
        pluralLabel: "Configuraciones Nóminas"
    },
    payrollReceipts:{
        name:"payrollReceipts",
        pluralLabel: "Recibos Nóminas"
    },
    calendar:{
        name:"calendar",
        pluralLabel: "Calendario"
    },
    expedients:{
      name:"expedients",
      pluralLabel:"Expedientes Digitales",
    },
    bankLayouts:{
        name:"bankLayouts",
        pluralLabel:"Layouts Bancarios",
    },
    notifications:{
        name:"notifications",
        pluralLabel:"Notificaciones",
    },
    paymentStrategies:{
        name:"paymentStrategies",
        pluralLabel:"Estrategias de Pago",
    },
    products:{
        name:"products",
        pluralLabel:"Productos",
    },
    intervalsPeriodicities:{
        name:"intervalsPeriodicities",
        pluralLabel:"Intervalos Periodicidades",
    },
};


export const arrangeImportItemsBySubject = (props = {}) => {
    const {subject} = props;
    const response = [];
    if(subject){
        const subjectData = PERMISSION_SUBJECTS[subject];
        if(subjectData?.config?.import?.entities){
            const entities = subjectData.config.import.entities;
            if(entities && entities.length>0){
                entities.forEach(entity => {
                    response.push({
                        labelSubject: entity.label,
                        enumSubject: entity.enum,
                        pluralSubjectLabel: subjectData.pluralLabel
                    })
                });
            }
        }
    }

    return response;
}
